<template>
  <page-main
    title="Activities, Awards, Projects, and Honors"
    :aside="[
      'What did you do in school that helps you stand out?',
      'Did you serve in any leadership positions? Receive any awards that you would highlight?',
      'Enter any competitions? What about projects you worked on?',
      'If you are not sure where an item would go, don\'t worry! Pick a spot, record it, and keep thinking!',
      'If you have been out of school for a long time, or you don\'t want to list anything, you can leave this section blank.'
    ]"
    nextText="Develop these items into lines for your resume"
    buttonTabIndex="5"
    eventName="nav"
    @nav="nav('/EducationEntryAwardFocus/' + key)"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Activities"
            titleText="Activities: "
            :optionsList="[]"
            :selected.sync="activities"
            tabindex="1"
            autofocus
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Awards"
            titleText="Awards: "
            :optionsList="[]"
            :selected.sync="awards"
            tabindex="2"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Projects"
            titleText="Projects: "
            :optionsList="[]"
            :selected.sync="projects"
            tabindex="3"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Honors"
            titleText="Honors: "
            :optionsList="[]"
            :selected.sync="honors"
            tabindex="4"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

// @ is an alias to /src
export default {
  name: "EducationEntryAwards",
  data: () => ({}),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }
  },
  methods: {
    save() {
      this.saveEducation();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapMutations("education", ["createEntry"]),
    ...mapActions("education", ["saveEducation"])
  },
  computed: {
    ...mapGetters("education", ["doesKeyExist"]),
    ...mapFields("education", {
      key: "key",
      activities: "educationAwards.activities",
      awards: "educationAwards.awards",
      honors: "educationAwards.honors",
      projects: "educationAwards.projects"
    })
  }
};
</script>
